.group {
  margin-right: 16px;
  margin-bottom: 8px;
  display: inline-block;
}

.group button {
  padding: 5px;
  border-color: rgba(0, 0, 0, 0.23);
  border-width: 1px;
  border-top-style: solid;
  border-right-style: solid;
  border-bottom-style: solid;
  background-color: #fff;
  cursor: pointer;
}

.group button:first-of-type {
  border-left-style: solid;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.group button:last-of-type {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.active svg {
  fill: #A10037;
}